.green-color,
.ant-modal-confirm-confirm,
.ant-modal-confirm-body > .anticon,
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #37762f !important;
}

.marg {
  margin: 15% 0;
}

.bg-green-color,
.ant-btn-primary {
  background-color: #37762f;
}

.ant-btn-primary:hover {
  background-color: #dff0de !important;
  border: 1px solid #37762f;
  color: #37762f !important;
}

.ant-btn-default {
  background-color: #ffffff;
  border-color: #d9d9d9;
}

.ant-btn-default:hover {
  background-color: #dff0de !important;
  border-color: #37762f !important;
  border: 1px solid #37762f !important;
  color: #37762f !important;
}

.ant-upload-wrapper,
.ant-upload-drag,
.ant-upload {
  padding: 0px 0px 8px 0px !important;
}

.border-2-gray {
  border: 2px solid gray;
}

.ant-layout .ant-layout-sider,
.ant-menu-dark .ant-layout .ant-layout-sider-children,
.ant-layout .ant-layout-sider-trigger {
  background: #dff0de;
  color: black;
}

.ant-layout {
  flex: none !important;
}

.ant-layout-sider,
.ant-layout-sider-dark,
.ant-layout-sider-has-trigger {
  flex: 0 0 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
  width: 230px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.ant-menu-light .ant-menu-item-selected {
  background: unset;
  color: #37762f;
  font-weight: 800;
}

.ant-modal-title {
  color: #37762f !important;
  font-weight: 800;
  font-size: 24px !important;
  line-height: 2;
}

.ant-modal-close-icon {
  color: #37762f !important;
}

.height-60 {
  height: 60px;
}

.ant-menu-light {
  background: unset !important;
}

.navbar {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  position: fixed;
  width: 100%;
}

.nav-logo {
  align-items: center;
  display: flex;
}

.nav-logo img {
  margin-top: 10px;
  margin-bottom: 4px;
  width: 88px;
  height: 81px;
  object-fit: cover;
  max-width: unset;
}

.nav-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px 0px;
  font-weight: 700;
  /* font-size: 28px; */
}

.nav-content-header {
  color: #37762f;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  /* font-size: 28px; */
}

.nav-globe {
  font-size: 40px;
  cursor: pointer;
  color: #37762f;
  padding-left: 8px;
}

.form-pad {
  padding: 0px 10px;
}

.globe {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  padding-bottom: 2rem;
}

.login-title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 1rem;
}

.login-content {
  padding-top: 3rem;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 66.67%, #deefdc 100%);
  height: 100vh;
}

.login-button {
  width: 100% !important;
  background-color: #37762f !important;
  color: #fff !important;
}

.input-label {
  color: #37762f;
  font-size: 14px;
  font-weight: 500;
}

.login-dev {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 45px;
}

.dev-title {
  font-size: 9px;
  letter-spacing: 8px;
  position: absolute;
  text-transform: uppercase;
}

.bg-silver-color {
  background: #f3f3f3;
}

.card-border {
  border: 1px solid rgba(55, 118, 47, 0.42);
}

.pay-bot-border {
  border-bottom: 2px solid #37762f;
}

.left-body {
  margin-top: 40px;
}

.refund-request-form {
  margin-top: 80px;
}

.make-payment {
  margin-top: 85px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
}

.side-bar {
  margin-top: 60px;
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.content-bar {
  margin-top: 60px;
  margin-left: 230px;
}

.navbar-background {
  z-index: 2;
}

.content-background {
  margin-top: 50px;
}
.scrollableContent {
  minheight: 300px;
  overflow-y: auto;
}
.profileForm {
  width: 1050px;
}
.p-items {
  width: 350px;
}
.i-items {
  width: 460px;
}
.p-body {
  width: 1200px;
}
.msgOTP {
  margin-top: 5px;
}
.custom-modal .ant-modal-title {
  font-size: 14px;
}
.popup {
  position: absolute;
  top: calc(50% + 10px);
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 999;
  width: 98%;
  min-height: 200px;
}
.popup-content {
  display: inline-block;
  margin-right: 10px;
}

.popup div {
  margin-bottom: 10px;
}
.amountInTotal {
  font-size: medium;
  color: #37762f;
}
.title {
  color: #37762f;
}
.amountInTotalFormat {
  font-weight: bolder;
}
.paymentsStats {
  width: 800px;
}
.bid-stat {
  font-size: medium;
}
.bid-stat-refund {
  font-size: medium;
  color: #808080;
}
.card-1 {
  color: #808080;
  font-size: 12px;
}
.card-2 {
  color: #808080;
  font-size: 13px;
}
.cancel-message {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  width: 100%;
  max-width: 800px;
  height: 500px;
}
.separator {
  height: 50px;
}
.menu-item-label {
  display: flex;
  align-items: center;
}

.notification-badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 8px;
  position: relative;
  top: -4px;
}

.relative {
  position: relative;
}

.tooltip {
  display: none;
}

.relative:hover .tooltip {
  display: block;
  width: 200px;
  text-align: center;
}
.icon-container {
  display: flex;
  align-items: center;
}

.icon-style {
  font-size: 16px;
  color: #37762f;
  margin-right: 8px;
  transition: color 0.3s ease;
  font-weight: bold;
  text-shadow: 0 1px 0 #000;
}

.titleHeadings {
  color: #37762f;
  font-size: 16px;
}
.icon-style:hover {
  color: goldenrod;
  cursor: pointer;
}

.custom-tag {
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
}

.custom-tag-success {
  background-color: steelblue;
}

.custom-tag-error {
  background-color: #f5222d;
}

.custom-tag-info {
  background-color: #1890ff;
}

.custom-tag-warning {
  background-color: #faad14;
}

.custom-table-header {
  background-color: #f5f5f5;
  color: #333;
}

.custom-table .ant-table-thead > tr > th {
  background-color: #f5f5f5;
  color: #333;
}

.custom-col-container {
  background-color: steelblue;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 8px;
}

.custom-status {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}
.ant-btn-default {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #dcdcdc;
}

.paymentsStats .ant-modal-content {
  background-color: steelblue;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-border {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #ffffff;
}

.bg-silver-color {
  background-color: #f0f2f5;
}

.spacing-row {
  height: 50px;
  border-bottom: 1px solid #37762f;
  width: 100%;
  font-family: Arial;
  font-weight: bold;
  font-size: small;
}

.request-ref {
  width: 1200px;
}
.request-ref form{
  align-items: center;     /* Center vertically */
}

.align-right {
  text-align: right;
  margin-top: 40px;
}

.spaced {
  margin-right: 10px;
}
.forHeader {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: #063970;
  height: 185px;
}

.forImage,
.forTitle {
  width: 50%;
  display: inline-block;
  vertical-align: top; /* aligns the content to the top */
}

.forTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.forImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.forImage img {
  width: 200px;
  height: 200px;
  margin-right: 5px;
  display: block;
  border-radius: 0px;
}

.forContent {
  display: flex;
  width: 100%;
}
.forForm,
.forImageContent {
  width: 50%;
  display: flex;
  vertical-align: top;
  padding: 20px;
  min-height: 550px;
}
.forImageContent img {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: block;
  border-radius: 10px;
}

.forHeader,
.forContent {
  display: flex;
  flex-wrap: wrap;
}
.main {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #063970;
  font-size: larger;
}
.forHeader,
.forContent {
  width: 100%;
}

.titleOne {
  font-size: 18px;
  color: white;
  font-weight: bold;
}
.forTitle p {
  margin: 0; /* Remove default margin */
  padding: 5px 0; /* Add vertical padding */
  text-align: center;
}
.formHeading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.login-form {
  font-size: 30px;
}
.font-semibold {
  font-size: 14px;
  color: #37762f;
  font-weight: bold;
}
.custom-option {
  font-size: 20px; /* Adjust size as needed */
}

@media (max-width: 600px) {
  .forImageContent {
    display: none;
  }
}
@media (max-width: 600px) {
  .forImage {
    display: none;
  }
}
@media (max-width: 600px) {
  .forForm {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .forTitle {
    width: 100%;
    min-height: 150px;
  }
}
@media (max-width: 600px) {
  form input,
  select {
    display: flex;
  }
}
.division-title {
  margin-top: 20px;
  background-color: #37762f;
  color: white;
  width: 94%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
/* Container for all the links */
.links-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px; /* Adjust this margin based on your needs */
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Each link item */
.link-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px; /* For small screens, add margin-bottom */
}

/* Separator between links */
.separator {
  color: #6c757d;
  margin: 0 10px;
}

/* Link styling */
.link {
  text-decoration: none;
  color: #37762f;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.link:hover {
  color: #007bff; /* Hover color */
}

/* Styling for active link */
.link.active {
  font-weight: bold;
  color: #ffffff;  /* Active color */
  background-color: #37762f;
  border-bottom: 2px solid #ffffff;
}

/* Focus styles for active links */
.link.active:focus, .link:focus {
  outline: none;
  border: 2px solid #37762f;
  box-shadow: 0 0 5px rgba(55, 118, 47, 0.7);
  background-color: #37762f;
  color: white;
}
/* Adjust layout on small screens */
@media (max-width: 768px) {
  .links-container {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }

  .separator {
    display: none; /* Hide separator on small screens */
  }

  .link-item {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Even smaller screens (mobile) */
@media (max-width: 480px) {
  .link {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}
/* General container styles */
.header-stat {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap when needed */
  gap: 20px; /* Spacing between the items */
  overflow-x: auto;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
}

/* Styles for each stat item */
.stat-item {
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  text-align: center;
  flex: 1 1 20%; /* By default, each item takes 20% width */
}

/* Background colors for each item */
.stat-item.bg-blue-100 {
  background-color: #f4f7fb;
}

.stat-item.bg-teal-100 {
  background-color: #e0f7fa;
}

.stat-item.bg-green-100 {
  background-color: #fff3e0;
}

.stat-item.bg-orange-100 {
  background-color: #e8f5e9;
}

.stat-item.bg-yellow-100 {
  background-color: #fff9c4;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header-stat {
    gap: 10px;
  }

  .stat-item {
    flex: 1 1 100%; /* Stack items vertically */
    margin-bottom: 10px; /* Add space between stacked items */
  }
}

/* Tablet and medium-sized screens */
@media (max-width: 1024px) {
  .stat-item {
    flex: 1 1 45%; /* On medium screens, each item takes 45% width */
  }
}

/* Desktop and large screens */
@media (min-width: 1025px) {
  .stat-item {
    flex: 1 1 20%; /* On large screens, each item takes 20% width */
  }
}
/* Tailwind CSS approach */
.responsive-title {
  font-size: 0.9rem; /* Default font size for larger screens */
  line-height: 1.5; /* Adjust line height for readability */
}

@media (max-width: 640px) { /* For small screens (xs and sm) */
  .responsive-title {
    font-size: 0.9rem; /* Smaller font size on smaller screens */
    line-height: 1.4; /* Adjust line height for readability */
  }
}

@media (max-width: 480px) { /* For extra small screens */
  .responsive-title {
    font-size: 1rem; /* Further reduce font size on very small screens */
    line-height: 1.3; /* Adjust line height for smaller screens */
  }
}
@media (max-width: 991px) {
  .side-bar .ant-layout-sider {
    position: fixed;
    z-index: 100;
  }

  .menu-toggle {
    display: block;
  }
}
.log-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: #4A4A4A;
}

.log-content {
  font-size: 0.9rem;
  color: #5A5A5A;
}

.footer-details {
  background-color: #F4F4F4; /* Light background for the log section */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.text-green-600 {
  color: #37762f;
  font-size: 0.9rem;
}

.text-blue-600 {
  color: #2563eb;
  cursor: pointer;
}

.text-blue-600:hover {
  text-decoration: underline;
}
.bold-small {
  font-weight: bold;
  font-size: 13px;
}




